<script>
  import { fly } from 'svelte/transition';
  import { notification } from './notificationStore.js';

  const DURATION = 5000

  export let autoClose, notice, alert

  if(notice) {
    $notification.notice = notice
  } else if(alert) {
    $notification.alert = alert
  }

  if(autoClose){
    setTimeout(() => { $notification = {} }, DURATION);
  }

</script>

{#if $notification.notice || $notification.alert}
  <div transition:fly="{{duration: 150, x: 150, opacity: 0.2}}" id="notification-bar" class="fixed left-0 right-0 z-50 px-2 mx-auto text-gray-700 md:px-0 top-2 md:w-auto md:right-6 md:left-auto">
    {#if $notification.notice}
      <div class="flex justify-center w-full px-4 py-2 text-sm font-medium bg-green-200 rounded-full">
        {$notification.notice}
      </div>
    {/if}

    {#if $notification.alert}
      <div class="flex justify-center w-full px-4 py-2 text-sm font-medium text-red-900 bg-red-100 rounded-full">
        {$notification.alert}
      </div>
    {/if}
  </div>
{/if}
