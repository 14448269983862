<script>
  let email = '';
</script>

<div class="mt-4">
  <h1 class="text-xl font-bold md:text-2xl text-primary-dark max-w-prose">Toploc, les locations de vacances nature en groupe 🌳</h1>

  <p class="mb-8 explainer">Ravi de vous revoir. Connectez-vous à votre compte.</p>

  <label class="block mb-1 label-base" for="email"></label>
  <input class="w-full input-base" type="email" id="email" name="email" bind:value={email} placeholder="Email" required />

  <div class="w-full md:flex gap-x-4 mt-6 {email.length < 2 ? 'opacity-60' : ''}">
    <a href="/users/sign_in?email={email}" class="block w-full text-center text-gray-700 bg-gray-100 md:w-1/2 btn-primary hover:bg-gray-200">Se connecter via mot de passe</a>
    <input class="w-full mt-4 md:w-1/2 btn-primary md:mt-0" type="submit" value="Recevoir un lien de connexion par email" />
  </div>

</div>
