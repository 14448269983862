<script>
  import { fly } from 'svelte/transition';
  import { onMount, onDestroy } from 'svelte';

  export let content, anchor

  export let classesTooltip = "bg-primary text-xs text-gray-50 rounded-lg p-3 leading-relaxed absolute top-0 shadow-lg"
  export let classesWrapper = "font-medium text-xs container relative text-gray-600"

  let isVisible = false
  let container

  const onMouseEnter = () => {
    isVisible = true
  }

  const onMouseLeave = () => {
    isVisible = false
  }

  onMount(() => {
    if (container !== null) {
      container.addEventListener('mouseenter', onMouseEnter);
      container.addEventListener('mouseleave', onMouseLeave);
    }
  });

  onDestroy(() => {
    if (container !== null) {
      container.removeEventListener('mouseenter', onMouseEnter);
      container.removeEventListener('mouseleave', onMouseLeave);
    }
  });

  const translateXValue = () => {
    return document.body.getBoundingClientRect().width > 620 ? "-50%" : "0%"
  }
</script>

<div class="{classesWrapper}" bind:this={container}>
  {#if isVisible}
    <div class="{classesTooltip}" style="transform: translate3d(calc({translateXValue()} + 1.25rem), -110%, 0);" transition:fly="{{delay: 0, duration: 120, x: 0, y: 15}}">
      <p class="w-44">{@html content}</p>
    </div>
  {/if}

  <p class="flex items-center cursor-pointer">
    {@html anchor}
  </p>
</div>
