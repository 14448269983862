<script>
  import { processePotentialNotification } from '../common/notificationStore.js';
  import { getHeaders } from "../headers.svelte";

  let emailAddress
  let loaderClass = "invisible"

  const sendForm = async () => {
    loaderClass = "visible"

    const response = await fetch(
      "/traveller_newsletter_subscriptions/",
      {
        headers: getHeaders(),
        method: 'POST',
        body: JSON.stringify({email: emailAddress})
      }
    );

    const data = await response.json()

    loaderClass = "invisible"
    processePotentialNotification(data)

    if(response.status == 201) {
      emailAddress = ""
    }
  }
</script>

<form class="justify-center mt-8 md:flex">
  <input class="w-full pr-8 text-gray-800 rounded-full placeholder:text-center md:placeholder:text-left input-base md:w-72" bind:value={emailAddress} autocomplete="email" type="email" placeholder="Votre adresse email">
  <button class="flex items-center justify-center w-full pl-0 pr-10 mt-2 ml-0 text-base md:text-lg md:w-auto md:mt-0 md:-ml-8 btn-secondary hover:bg-secondary" on:click|preventDefault={sendForm}>

    <svg class="w-4 h-4 {loaderClass} mr-2 ml-3 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    S'inscrire à la newsletter
  </button>
</form>
