<script>
  export let inputName
  export let value = false

  const toggleSwitch = () => {
    value = !value
  }
</script>

<input type="checkbox" checked=true value="off" class="hidden" name={inputName} />
<input type="checkbox" bind:checked={value} class="hidden" name={inputName} />

<div class="w-12 h-6 p-1 {value ? 'bg-primary' : 'bg-gray-300'} cursor-pointer rounded-full shadow-inner" on:click={toggleSwitch}>
  <div class="w-4 h-4 bg-white rounded-full shadow transition-all" style="transform: translateX({value ? '1.4rem' : '0'})"></div>
</div>


