<script>
  import { Dies } from 'dies'
  import  'dies/dist/index.css'
  import { addMonths } from 'date-fns'
  import { addDays } from 'date-fns'
  import { endOfMonth } from 'date-fns';
  import { startOfMonth } from 'date-fns';
  import { onMount } from 'svelte';

  export let listingId;
  export let monthCount = 4;

  export let config, initialDate

  let defaultConfig = {
    "languages": ["fr"],
    "accentColor": "#006B52"
  }

  let events = []
  let startDate = startOfMonth(new Date(initialDate || Date.now()))
  let endDate = endOfMonth(addMonths(startDate, monthCount))

  function formatDateForUrl(date) {
    return encodeURIComponent(date.toDateString());
  }

  function onMonthChanged(e){
    fetchBookings(e.detail.startDate, e.detail.endDate);
  }

  async function fetchBookings(newStartDate, newEndDate){
    // empty events to show an intermediate state, when the user has switched to a different month, but the data hasn't loaded yet
    events = []
    const response = await fetch(`/listings/${listingId}/bookings?start_date=${formatDateForUrl(newStartDate)}&end_date=${formatDateForUrl(newEndDate)}`)
    const data = await response.json()

    events = data["bookings"].map((booking) => {
      // create js datetime objects heres from parsing date attributes, so that the dates are always
      // correct wrt to local timezones for users
      const matchStartDate = booking.data.attributes.start_date.match(/(\d{4})-(\d{1,2})-(\d{1,2})/)
      const matchEndDate = booking.data.attributes.end_date.match(/(\d{4})-(\d{1,2})-(\d{1,2})/)

      // substract 1 day from end date, as we want to show each night as booked
      // the day of the checkout should be marked as free, as new guests can arrive
      return {
        startDate: addDays(new Date(matchStartDate[1], parseInt(matchStartDate[2]) - 1, matchStartDate[3]), -0),
        endDate: addDays(new Date(matchEndDate[1], parseInt(matchEndDate[2]) - 1, matchEndDate[3]), -1),
        markerColor: booking.data.attributes.i_cal_feed_id != undefined ? "#006B52" : "#EA9646"
      }
    });

    startDate = newStartDate
  }

  onMount( async () => { fetchBookings(startDate, endDate); });
</script>

<Dies
startDate={startDate}
events={events}
config={ { ...config, ...defaultConfig } }
monthCount={monthCount}
on:monthChanged={onMonthChanged}>
</Dies>
